/*** 

Adjusted color swatches 

***/
// Adjusted USWDS swatches
$theme-color-primary-darker: #042D36;
$theme-color-accent-cool: #0BB7C2;

// New USWDS swatches
$unity-pink: #D93778;
$unity-orange-adj: #E07616;
$unity-blue-adj: #0BB7C2;
$unity-orange-active: #D34200;
$unity-yellow: #FAE64C;
$unity-purple: #7E4E96;
$cto-pop-2: #E1004B;
$cto-pop-2-hover: #720034;


// Setting up the Barlow Condensed font 
$theme-typeface-tokens: (
  public-sans: (
    display-name: "PublicSans",
    cap-height: 364px
  )
) !default;

@font-face {
    font-family: PublicSans;
    font-weight: 800;
    src: url(../fonts/public-sans/PublicSans-Bold.ttf);
}

@font-face {
    font-family: PublicSans;
    src: url(../fonts/public-sans/PublicSans-Light.ttf);
}

// $theme-font-type-cond-custom-src: $theme-font-barlow-condensed !default;

$theme-font-type-serif: "public-sans";
$theme-font-type-sans: "public-sans";

.bg-white {
    background-color: white;
}

.bg-base-dark {
    background-color: $theme-color-primary-darker !important;
}
.bg-unity-blue {
    background-color: white;
}

.text-white {
    color: white;
}

.bg-unity-yellow {
    background-color: $unity-yellow;
}

.text-accent-cool {
    color: $theme-color-accent-cool;
}

.bg-unity-purple {
    background-color: $unity-purple !important;
}

.text-barlow-heading {
    font-family: PublicSans;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.015em;
}

.text-barlow-title {
    font-family: PublicSans;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.015em;
}

.text-barlow-logo {
    font-family: PublicSans;
    font-style: normal; 
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
}

.text-primary-darker {
    color: $theme-color-primary-darker !important;
}

.bg-primary-darker {
    background-color: black!important;
}

.text-unity-blue {
    color: $unity-blue-adj !important;
}

.bg-unity-pink {
    background-color: $unity-pink;
}

// .bg-unity-about {
//     background-image: url("../img/_A3A3659_CMYK\ 1.png") !important;
// }

// .bg-unity-contact {
//     background-image: url("../img/_A3A3388_CMYK 1.png") !important;
// }

.img-circular {
    border-radius: 50%;
}

.agency-grid {
    display: flex;
    flex-direction: row column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;

    a {
        display: inline-block;
    }
}

.agency-item {
    max-height: 80px;
    max-width: 220px;
    margin-left: 50px;
    margin-right: 40px;
}

.agency-adjustment {
    margin-top: 50px;
}

.agency-adjustment-first {
    margin-left: 0px;
    margin-right: 30px;
}

.footer-text {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
}

.footer-padding {
    margin-left: 220px;
    padding-top: 30px;
}

.usa-footer__return-to-top {
    background-color: transparent;
}

.wrap-header {
    height: auto !important;
    overflow: hidden;
    margin-bottom: 0px;
}

.banner-adjustment {
    height: 22px;
    min-height: 22px !important;
    max-height: 24px !important;
}

.usa-button {
    background-color: white !important;
    color: $theme-color-primary-darker!important;
    border: 2px solid #042d36!important;
}

.usa-button:hover {
    background-color: $theme-color-accent-cool !important;
}

.usa-button:active {
    background-color: $theme-color-primary-darker !important;
    color: white !important;
}

.usa-prose {
    font-family: PublicSans !important;
}

.usa-menu-btn {
    background-color: $cto-pop-2 !important;
}

.usa-menu-btn:hover {
    background-color: $cto-pop-2-hover !important;
}

.usa-nav__primary .usa-current {
   color: $theme-color-primary-darker !important;
}

@media (max-width: 370px) {
    .govbanner {
        visibility: hidden;
    }
}

@media (min-width: 910px)
{
    .usa-nav__link::after {
        background-color: $cto-pop-2 !important;
    }

    .usa-nav__link:hover {
        color: $theme-color-primary-darker !important;
    }

    .nowrap {
        white-space: nowrap !important;
    }
}

@media (max-width: 910px) {
    .mobile-menu {
        background-color: white !important;
    }

    .usa-nav__link {
        color: $theme-color-primary-darker !important;
    }

    .usa-nav__link:hover {
        // color: $cto-pop-2 !important;
        font-weight: bold;
    }
    
    .usa-nav__primary-item {
        border: none !important;
    }

    .usa-nav__primary .usa-current::after {
        background-color: $cto-pop-2 !important;
    }

    .usa-nav__primary-item:hover {
        // color: $cto-pop-2 !important;
        font-weight: bold;
    }

    .usa-menu-btn {
        align-self: flex-start;
    }

    .usa-accordion {
        height: 100%;
        .usa-banner__header {
            min-height: auto!important;
            height:100%;
            padding-bottom: 0!important;
            padding-top: 0!important;
    
            .usa-banner__inner {
                height: 100%;
                align-items: center;
            }

        }
    }
    
}

@import '../../../node_modules/uswds/dist/scss/uswds.scss';
